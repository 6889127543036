import NextImage from "next/image";

export default function ImageContent({
  value,
}: {
  value: {
    url: string;
    size: "SMALL" | "MEDIUM" | "LARGE";
  };
}) {
  const getContainerClass = () => {
    switch (value.size) {
      case "SMALL":
        return "w-[360px] aspect-[16/9] sp:w-full";
      case "MEDIUM":
        return "w-[480px] aspect-[16/9] sp:w-full";
      case "LARGE":
        return "w-full aspect-[16/9]";
      default:
        return "w-[360px] aspect-[16/9] sp:w-full";
    }
  };
  return (
    <div className="w-full my-8">
      <div className={`relative ${getContainerClass()}`}>
        <NextImage
          src={`${process.env.NEXT_PUBLIC_IMAGE_URL_BASE}${value.url}`}
          layout="fill"
          objectFit="cover"
          alt={value.url}
          title={value.url}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </div>
    </div>
  );
}
