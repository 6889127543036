import React from "react";

type Props = {
  children: React.ReactNode;
};

export default function BorderList({
  children,
}: Props) {
  return (
    <ul className="divide-y divide-gray-300 py-4 sp:py-2 sp:px-4 tb:px-8">
      {children}
    </ul>
  );
}
