"use client";

import { signIn as signInByNextAuth } from "next-auth/react";
import { useLocale } from 'next-intl';

export default function SignInModal() {
  const locale = useLocale();

  const handleSignInWithGoogle = async () => {
    try {
      const { GoogleAuthProvider } = await import('firebase/auth');
      const provider = new GoogleAuthProvider();
      const { auth } = await import('@/firebase/client');
      const { signInWithPopup } = await import('firebase/auth');
      const credential = await signInWithPopup(auth, provider);
      const idToken = await credential.user.getIdToken();
      signInByNextAuth("credentials", {
        idToken,
        callbackUrl: window.location.pathname,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignInWithApple = async () => {
    try {
      const { OAuthProvider } = await import('firebase/auth');
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({ locale: locale });
      const { auth } = await import('@/firebase/client');
      const { signInWithPopup } = await import('firebase/auth');
      const result = await signInWithPopup(auth, provider);
      // NOTE: getIdTokenで取得したtokenでないと検証に失敗する
      // const credential = OAuthProvider.credentialFromResult(result);
      const idToken = await result.user.getIdToken();
      signInByNextAuth("credentials", {
        idToken,
        callbackUrl: window.location.pathname,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <dialog id="signin-modal" className="modal">
      <div className="modal-box bg-custom-light-gray">
        <div className="flex flex-col items-center">
          <h3 className="font-light text-2xl">
            <span className="text-primary font-medium">Join HelloSake</span>
            <span className="mx-2 text-custom-gray">|</span>
            <span className="font-semibold">Login</span>
          </h3>
          <div className="flex flex-col gap-4 w-8/12 mt-2 sp:w-full py-8">
            <button
              className="btn btn-block bg-white text-neutral border-none"
              onClick={handleSignInWithGoogle}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" /><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" /><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" /><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" /><path d="M1 1h22v22H1z" fill="none" /></svg>
              Sign in with Google
            </button>
            <button
              className="btn btn-block bg-black text-white hover:bg-neutral border-none"
              onClick={handleSignInWithApple}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#fff">
                <g>
                  <g>
                    <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573,c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z" />
                    <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334,c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0,c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019,c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464,c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648,c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z" />
                  </g>
                </g>
              </svg>
              Sign in with Apple
            </button>
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
}
