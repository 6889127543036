export default function TextLinkContent({
  value,
}: {
  value: {
    text: string;
    url: string;
  };
}) {
  return (
    <a href={value.url} title={value.text} target="_blank" className="link link-secondary link-hover text-lg min-h-8 sp:text-base">
      {value.text}
    </a>
  );
}
