import { Link } from '@/navigation';
import Image from "next/image";
import { Review, Sake, User } from "@/types";
import {
  RatingStar,
  UserCassette,
} from "@/components";
import { HandThumbUpIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { timeAgo } from "@/utils";

type Props = {
  review: Review;
  hideSake?: boolean;
  as?: keyof JSX.IntrinsicElements;
};

export default function ReviewItem({
  review,
  hideSake,
  as: Component = 'li',
}: Props) {
  const sake = review.sake as Sake;
  const user = review.user as User;
  if (!sake || !user) return null;
  return (
    <Component>
      <Link href={`/products/${sake.id}/reviews/${review.id}`} title={`${sake.name}'s review by ${user.name}`}>
        <div className="flex p-5 sp:p-2 hover:bg-gray-200">
          {!hideSake && (
            <div className="w-20 min-w-20 flex justify-center items-center mr-8 sp:w-16 sp:min-w-16 sp:mr-4">
              <Image
                src={sake.labelImageUrl ? `${process.env.NEXT_PUBLIC_SAKE_LABEL_IMAGE_URL_BASE}${sake.labelImageUrl}` : "/images/no-image-sake.png"}
                width={40}
                height={40}
                alt={sake.name}
                title={sake.name}
              />
            </div>
          )}
          <div className="grow">
            {!hideSake && <h3 className="text-secondary font-bold text-xl sp:text-base">{sake.name}</h3>}
            <div className="mt-2 mb-2">
              <UserCassette
                url={user.photoUrl}
                name={user.name}
                subText={timeAgo(review.createdAt)}
                isVerified={!!user.isVerified}
              />
            </div>
            <div className="flex items-center">
              <RatingStar rating={review.rating} />
              <span className="text-primary font-bold ml-2">{review.rating}</span>
            </div>
            {review.description ? (
              <p className="my-3 sp:text-sm">{review.description}</p>
            ) : (
              <p className="my-3 text-custom-gray sp:text-sm">There is no comment data.</p>
            )}
            <div className="flex items-center">
              <HandThumbUpIcon className="h-6 w-6 sp:h-4 sp:h-4" />
              <span className="ml-1.5 sp:ml-0.5 sp:text-sm">{review.likedUserIds?.length || 0}</span>
            </div>
          </div>
          <ChevronRightIcon className="h-5 w-5 min-h-5 min-w-5 sp:h-4 sp:w-4 sp:min-h-4 sp:min-w-4" />
        </div>
      </Link>
    </Component>
  );
}
