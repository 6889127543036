import { Link } from '@/navigation';

type Props = {
  items: {
    text: string;
    href?: string;
  }[];
};

export default function Breadcrumbs({
  items,
}: Props) {
  return (
    <ol className="flex sp:whitespace-nowrap sp:overflow-scroll sp:py-2 sp:pr-4">
      {items.map((item, index) => {
        const isLastItem = index === items.length - 1;
        return (
          <li
            key={index}
            className={!isLastItem ? 'after:content-["/"] after:mx-2 sp:text-sm' : 'sp:text-sm'}
          >
            {item.href ? (
              <Link href={item.href} title={item.text}>
                <span className="text-secondary">{item.text}</span>
              </Link>
            ) : (
              <span>{item.text}</span>
            )}
          </li>
        );
      })}
    </ol>
  );
}
