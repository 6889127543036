import Body from "./Body";
import { getBrewery } from "@/services/brewery";

export default async function BreweryContent({
  value,
}: {
  value: string;
}) {
  const { result: brewery } = await getBrewery(value);

  return <Body brewery={brewery} />;
}
