export default function BulletListContent({
  value,
}: {
  value: string[];
}) {
  return (
    <div className="my-4 pl-2">
      <ul className="list-disc list-inside text-lg font-extralight sp:text-base">
        {value.map((v, index) => <li key={index}>{v}</li>)}
      </ul>
    </div>
  );
}
