type Props = {
  bestTemperatures?: string[];
};

export default function Temperature({
  bestTemperatures,
}: Props) {
  return (
    <div className="py-4 flex">
      {bestTemperatures ? (
        <div className="flex flex-col-reverse items-center">
          <input type="checkbox" className="peer toggle mt-4" />
          <ul className="flex peer-checked:hidden flex-wrap gap-4">
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("5-15") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                5-15
                <span className="text-xs font-light">℃</span>
              </p>
              <p className="text-sm sp:text-xs">Chilled</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("16-25") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                16-25
                <span className="text-xs font-light">℃</span>
              </p>
              <p className="text-sm sp:text-xs">Room Temp</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("26-35") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                26-35
                <span className="text-xs font-light">℃</span>
              </p>
              <p className="text-sm sp:text-xs">Slightly Warm</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("36-45") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                36-45
                <span className="text-xs font-light">℃</span>
              </p>
              <p className="text-sm sp:text-xs">Warm</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("46-") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                46
                <span className="text-xs font-light">℃</span>
                ~
              </p>
              <p className="text-sm sp:text-xs">Hot</p>
            </li>
          </ul>
          <ul className="hidden peer-checked:flex flex-wrap gap-4">
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("5-15") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                41-59
                <span className="text-xs font-light">℉</span>
              </p>
              <p className="text-sm sp:text-xs">Chilled</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("16-25") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                61-77
                <span className="text-xs font-light">℉</span>
              </p>
              <p className="text-sm sp:text-xs">Room Temp</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("26-35") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                79-95
                <span className="text-xs font-light">℉</span>
              </p>
              <p className="text-sm sp:text-xs">Slightly Warm</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("36-45") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                97-113
                <span className="text-xs font-light">℉</span>
              </p>
              <p className="text-sm sp:text-xs">Warm</p>
            </li>
            <li className={`flex flex-col items-center w-24 ${bestTemperatures && bestTemperatures.includes("46-") ? "" : "text-custom-gray"}`}>
              <p className="text-xl font-light sp:text-lg">
                115
                <span className="text-xs font-light">℉</span>
                ~
              </p>
              <p className="text-sm sp:text-xs">Hot</p>
            </li>
          </ul>
        </div>
      ) : (
        <div className="text-lg text-custom-gray sp:text-base">no data</div>
      )}
    </div>
  );
}
