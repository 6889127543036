"use client";

import Body from "./Body";
import { useReview } from '@/hooks/useReview';

export default function ReviewContent({
  value,
}: {
  value: string;
}) {
  const { data: review, isLoading } = useReview(value)

  if (isLoading || !review) return null;

  return <Body review={review} />;
}
