import Body from "./Body";
import { getReview } from '@/services/review';

export default async function ReviewContent({
  value,
}: {
  value: string;
}) {
  const { result: review } = await getReview(value);

  return <Body review={review} />
}
