type Props = {
  text: string;
};

export default function Heading({
  text,
}: Props) {
  return (
    <h2 className="text-3xl font-medium sp:text-xl">
      {text}
    </h2>
  );
}
