import { Link } from '@/navigation';
//import LocaleSwitcher from "@/components/LocaleSwitcher";
import { SignInButton } from "@/components";
import Image from "next/image";

export default function Header() {
  return (
    <header className="mb-4 py-3 px-6 flex justify-between items-center sp:mb-2 sp:py-1 sp:px-4 tb:px-8">
      <Link href="/" title="HelloSake" className="flex items-center">
        <Image
          src="/images/logo.avif"
          alt="HelloSake"
          title="HelloSake"
          width={14}
          height={34}
        />
        <h1 className="font-semibold text-2xl ml-2 sp:text-xl">HelloSake</h1>
      </Link>
      <div className="pr-2">
        <SignInButton />
        {/*<LocaleSwitcher />*/}
      </div>
    </header>
  );
}
