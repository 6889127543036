import { Link } from '@/navigation';
import { Brewery } from "@/types";
import { MapPinIcon } from "@heroicons/react/24/outline";
import Image from "next/image";

type Props = {
  brewery: Brewery;
  className?: string;
};

export default function BreweryCard({
  brewery,
  className,
}: Props) {
  return (
    <li className={`${className || ""} w-44`}>
      <Link href={`/breweries/${brewery.id}`} title={brewery.name} className="w-full">
        <div className="card card-compact custom-shadow">
          <figure>
            <Image
              src={brewery.imagePath ? `${process.env.NEXT_PUBLIC_IMAGE_URL_BASE}${brewery.imagePath}` : "/images/no-image.png"}
              width={200}
              height={100}
              alt={brewery.name}
              title={brewery.name}
            />
          </figure>
          <div className="card-body">
            <h3 className="text-base font-medium leading-tight line-clamp-2 sp:text-sm sp:leading-tight">{brewery.name}</h3>
            <div className="flex items-center mt-1">
              <MapPinIcon className="h-6 w-6 sp:h-4 sp:w-6 sp:-ml-1" />
              <p className="text-xs truncate sp:-ml-1">{brewery.prefecture || "-"}</p>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}
