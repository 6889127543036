import { User } from '@/types';
import useSWR from 'swr';

const fetcher = (url: string): Promise<User> =>
  fetch(url)
    .then((res) => res.json())
    .then((res) => res.result);

export const useCurrentUser = () => {
  const res = useSWR<User>("/api/users", fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return res;
};
