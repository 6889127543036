"use client";

import { useRouter } from 'next/navigation';

type Props = {
  items: {
    text: string;
    href: string;
    selected: boolean;
  }[];
};

export default function Select({
  items,
}: Props) {
  const router = useRouter();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue) {
      router.push(selectedValue);
    }
  };

  return (
    <select
      onChange={handleChange}
      className="select select-bordered select-black w-full max-w-xs"
      value={items.find(item => item.selected)?.href || ""}
    >
      {items.map((item, index) => (
        <option key={index} value={item.href}>
          {item.text}
        </option>
      ))}
    </select>
  );
}
