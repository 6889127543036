export default function ButtonLinkContent({
  value,
}: {
  value: {
    text: string;
    url: string;
  };
}) {
  return (
    <div className="flex justify-center w-full my-6">
      <a href={value.url} title={value.text} target="_blank">
        <button className="btn btn-primary btn-wide text-base font-bold px-8 text-white rounded-full">{value.text}</button>
      </a>
    </div>
  );
}
