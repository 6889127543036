"use client";

import Body from "./Body";
import { useBrewery } from "@/hooks/useBrewery";

export default function BreweryContent({
  value,
}: {
  value: string;
}) {
  const { data: brewery, isLoading } = useBrewery(value)

  if (isLoading || !brewery) return null;

  return <Body brewery={brewery} />;
}
