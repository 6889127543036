import { Link } from '@/navigation';
import { getTranslations } from 'next-intl/server';

export default async function Footer() {
  const t = await getTranslations('Footer');

  return (
    <footer className="flex justify-center mt-28 sp:mt-20">
      <div className="w-full max-w-[1080px] border-t border-gray-300 py-12 sp:py-8">
        <div className="flex justify-between mb-14 sp:justify-center sp:mb-8">
          <div>
            {/* SNSアイコン等 */}
          </div>
          <nav className="">
            <ul className="flex justify-center items-center gap-8 sp:gap-6">
              <li className="text-gray-400 sp:text-sm">
                <Link href="/terms-of-use" title={t('termsOfUse')}>{t('termsOfUse')}</Link>
              </li>
              <li className="text-gray-400 sp:text-sm">
                <Link href="/privacy-policy" title={t('privacyPolicy')}>{t('privacyPolicy')}</Link>
              </li>
              <li className="text-gray-400 sp:text-sm">
                <Link href="/contact" title={t('contact')}>{t('contact')}</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="flex justify-center">
          <p className="text-sm text-gray-400 sp:text-xs">© HelloSake 2024</p>
        </div>
      </div>
    </footer>
  );
}
