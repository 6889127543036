import { YouTubeEmbed } from "@next/third-parties/google";

export default function YouTubeContent({
  value,
}: {
  value: string;
}) {
  return (
    <div className="youtube-embed-container flex justify-center w-[720px] max-w-full aspect-video my-10 sp:my-8">
      <YouTubeEmbed videoid={value} />
    </div >
  );
}
