import { z } from "zod"

export const validationContactSchema = z.object({
  name: z
    .string()
    .nonempty("Please enter your name."),
  email: z
    .string()
    .nonempty("Please enter your email.")
    .email("Invalid email format."),
  message: z
    .string()
    .nonempty("Please enter your message."),
})
