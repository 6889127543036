import Link from 'next/link';

type Props = {
  current: number;
  total: number;
};

export default function Pagination({
  current,
  total,
}: Props) {
  // ロジック
  // - 最大で5個表示
  // - currentを真ん中にして、左右に2個ずつ
  const maxVisiblePages = 5;
  const pages = [];

  // ページングの範囲を計算
  const startPage = Math.max(
    1,
    Math.min(
      current - Math.floor(maxVisiblePages / 2),
      total - maxVisiblePages + 1
    )
  );
  const endPage = Math.min(startPage + maxVisiblePages - 1, total);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  const nonPagination = pages.length === 1;
  return (
    <div className="join">
      {pages.map((page) => (
        <Link key={page} href={`?page=${page}`} title={`page=${page}`}>
          <button className={`join-item h-10 w-10 ${page === current ? "bg-black text-white" : "bg-gray-200"} ${nonPagination ? "rounded" : ""}`}>
            {page}
          </button>
        </Link>
      ))}
    </div>
  );
}
