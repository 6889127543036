"use client";

import { useState } from "react";
import { useSession, signOut } from "next-auth/react";
import { Link } from '@/navigation';
import Image from "next/image";
import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  UserCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";

export default function CurrentUser() {
  const { data: session } = useSession();
  const { data: currentUser, isLoading } = useCurrentUser();
  // daisyUIではliをclickした時に閉じるという動作を実現できないため、別途stateで管理する
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (!session || !currentUser || isLoading) return null;

  const handleItemClick = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="dropdown dropdown-end">
      <div
        tabIndex={0}
        role="button"
        className="text-neutral cursor-pointer"
        onClick={() => setIsDropdownOpen(prev => !prev)}
      >
        {
          currentUser.photoUrl ? (
            <Image
              src={currentUser.photoUrl}
              width={40}
              height={40}
              alt={currentUser.name}
              unoptimized
              className="rounded-full min-h-[40px] min-w-[40px] h-[40px] w-[40px] object-cover"
            />
          ) : (
            <UserCircleIcon width="40" height="40" />
          )}
      </div>
      {isDropdownOpen && (
        <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-boxa rounded-md z-[1] w-80 p-2 shadow">
          <li onClick={handleItemClick}>
            <Link
              href="/account/edit"
              title="Account Edit"
              className="rounded-md"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {
                    currentUser.photoUrl ? (
                      <Image
                        src={currentUser.photoUrl}
                        width={56}
                        height={56}
                        alt={currentUser.name}
                        unoptimized
                        className="rounded-full min-h-[56px] min-w-[56px] h-[56px] w-[56px] object-cover"
                      />
                    ) : (
                      <UserCircleIcon width="56" height="56" />
                    )}
                  <div className="ml-4">
                    <p className="text-lg font-semibold w-40 line-clamp-1">{currentUser.name}</p>
                    <p className="text-sm text-neutral font-extralight w-40 line-clamp-1">{`@${currentUser.id}`}</p>
                  </div>
                </div>
                <ChevronRightIcon width="16" height="16" />
              </div>
            </Link>
          </li>
          <li
            className="border-t mt-2 pt-2"
            onClick={handleItemClick}
          >
            <a
              className="text-base text-custom-red font-normal rounded-md"
              onClick={() => signOut({ callbackUrl: "/", redirect: true })}
            >
              Log out
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}
