import React from "react";

type Props = {
  children: React.ReactNode;
};

export default function Carousel({
  children,
}: Props) {
  return (
    <ul className="carousel w-full gap-4 p-2 scroll-pl-2 sp:px-4 sp:scroll-pl-4 tb:px-8 tb:scroll-pl-8">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<any>, {
            className: `${child.props.className || ""} carousel-item`,
          });
        }
        return child;
      })}
    </ul>
  );
}
