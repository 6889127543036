import { Link } from '@/navigation';
import Image from "next/image";
import { UserCassette } from "@/components";
import { Article } from "@/types";
import { formatDate } from "@/utils";

type Props = {
  article: Article;
};

export default function ArticleItem({
  article,
}: Props) {
  const author = article.author;

  if (!author) return null;

  return (
    <li className="py-6 sp:py-4">
      <Link href={`/articles/${article.id}`} title={article.title}>
        <div className="flex p-5 sp:p-2 hover:bg-gray-200">
          <div className="mr-16 sp:mr-4">
            <UserCassette
              url={author.image}
              name={author.name}
              subText={formatDate(article.publishedAt as string)}
              isVerified={false}
            />
            <h3 className="text-secondary font-semibold text-2xl mt-3 sp:text-base sp:mt-2 sp:leading-none">{article.title}</h3>
            <p className="text-neutral font-light line-clamp-2 mt-3 sp:text-xs sp:mt-1 sp:line-clamp-1">{article.description}</p>
          </div>
          <Image
            src={article.mainImageUrl ? `${process.env.NEXT_PUBLIC_IMAGE_URL_BASE}${article.mainImageUrl}` : "/images/no-image.png"}
            width={160}
            height={160}
            alt={article.title}
            title={article.title}
            className="w-40 h-40 min-w-40 object-cover sp:w-24 sp:h-24 sp:min-w-24"
          />
        </div>
      </Link>
    </li>
  );
}
