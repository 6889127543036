import { Link } from '@/navigation';
import Image from "next/image";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { Brewery } from "@/types";

export default function Body({
  brewery,
}: {
  brewery: Brewery;
}) {
  return (
    <div className="my-16 sp:my-10">
      <div className="flex items-center sp:flex-col">
        <div className="basis-2/6 flex justify-center items-center mr-8 sp:basis-auto sp:mr-0">
          <Image
            src={brewery.imagePath ? `${process.env.NEXT_PUBLIC_IMAGE_URL_BASE}${brewery.imagePath}` : "/images/no-image.png"}
            width={360}
            height={360}
            alt={brewery.name || "-"}
            title={brewery.name || "-"}
          />
        </div>
        <div className="basis-4/6 sp:basis-auto sp:p-0 sp:mt-4">
          <h3 className="font-semibold text-3xl sp:text-xl">{brewery.name || "-"}</h3>
          <div className="flex items-center mt-1">
            <MapPinIcon className="h-6 w-6 sp:h-4 sp:w-4" />
            <p className="text-sm truncate">{brewery.prefecture || "-"}</p>
          </div>
          {brewery.url && (
            <div className="flex items-center mt-4 sp:text-sm">
              <p className="font-medium mr-2">HP</p>
              <a href={brewery.url} target="_blank" className="text-secondary">{brewery.url}</a>
            </div>
          )}
          <div className="whitespace-pre-line bg-custom-light-gray font-light p-6 mt-4 sp:p-4 sp:text-sm">{brewery.description || "no description"}</div>
          <div className="flex justify-center mt-6 sp:mt-4">
            <Link
              href={`/breweries/${brewery.id}`}
              title={brewery.name}
              className="text-secondary font-bold"
            >
              <button className="btn btn-primary btn-wide text-base font-bold px-8 text-white rounded-full">
                View more
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
